import React, { useContext, useState } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonIcon,
  IonButtons,
  IonCol,
  IonGrid,
  IonRow,
  IonLabel,
  IonListHeader,
  IonList,
  IonItem,
  IonInput,
  IonCard,
  IonCardContent,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useHttpClient } from "../../hooks/http-hook";
import AuthContext from "../../context/auth-context";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useForm } from "react-hook-form";
import GoogleMapAuto from "./GoogleMapsAuto";

// set the default values for the controls
let initialValues = {
  shipping_name: "",
  shipping_surname: "",
  shipping_company: "",
  shipping_phone: "",
  shipping_address_1: "",
  shipping_address_2: "",
  shipping_city: "",
  shipping_zip: "",
  shipping_country: "",
};

const AddNewAddressModal: React.FC<{
  show: boolean;
  selectedOrder: string;
  onSaved: () => void;
  onCancel: () => void;
}> = (props) => {
  const {
    control,
    handleSubmit,
    formState,
    reset,
    errors,
    getValues,
    setValue,
  } = useForm({
    defaultValues: { ...initialValues },
    mode: "onChange",
  });
  const authCtx = useContext(AuthContext);
  const { sendRequest, isLoading } = useHttpClient();
  const [showShippingAddressFields, setShowShppingAddressFields] =
    useState<Boolean>(false);
  const sendAddressHandler = async (data: any) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}api/ulule/newAddress/${props.selectedOrder}`,
        "POST",
        JSON.stringify(data),
        {
          "Content-Type": "application/json",
        }
      );
      props.onSaved();
    } catch (err) {
      console.log(err);
    }
  };

  const onShippingChangeHandler = () => {
    setShowShppingAddressFields(false);
  };

  const onSelectAddressHandler = (
    address: String,
    city: String,
    zip: String,
    country: String
  ) => {
    setShowShppingAddressFields(true);
    setValue("shipping_address_1", address.toString());
    setValue("shipping_zip", zip.toString());
    setValue("shipping_city", city.toString());
    setValue("shipping_country", country.toString());
  };

  return (
    <IonModal isOpen={props.show} backdropDismiss={false}>
      <IonHeader className="ion-no-border">
        <IonToolbar color="undefined" className="">
          <IonButtons slot="end">
            <IonButton onClick={props.onCancel}>
              <IonIcon size="large" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="undefined">
        <form onSubmit={handleSubmit((data) => sendAddressHandler(data))}>
          <IonGrid>
            <IonRow>
              <IonCol size="12" className="ion-padding-bottom">
                <IonListHeader
                  key="billing_title"
                  className="ion-padding-bottom"
                  mode="ios"
                >
                  Nouvelle adresse
                </IonListHeader>
                <IonCard mode="md">
                  <IonCardContent>
                    <IonList>
                      {/* shipping surname */}
                      <IonItem mode="md" key="shipping_surname">
                        <IonLabel
                          color="medium shade"
                          className="ion-text-wrap"
                          position="floating"
                        >
                          Prénom *
                        </IonLabel>
                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <IonInput
                              type="text"
                              inputmode="text"
                              autocomplete="on"
                              name="name"
                              autocorrect="on"
                              aria-autocomplete="list"
                              autofocus={true}
                              onIonInput={onChange}
                              //onIonChange={onShippingChangeHandler}
                              clearInput={true}
                              value={value}
                              enterkeyhint="next"
                              aria-invalid={
                                errors.shipping_surname ? "true" : "false"
                              }
                            />
                          )}
                          control={control}
                          name="shipping_surname"
                          rules={{
                            required: "Champs requis",
                            minLength: {
                              value: 2,
                              message: "Trop court",
                            },
                            maxLength: {
                              value: 50,
                              message: "Trop long",
                            },
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="shipping_surname"
                          as={<div style={{ color: "red" }} />}
                        />
                      </IonItem>
                      {/* shipping name */}
                      <IonItem mode="md" key="shipping_name">
                        <IonLabel
                          color="medium shade"
                          className="ion-text-wrap"
                          position="floating"
                        >
                          Nom *
                        </IonLabel>
                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <IonInput
                              id="name"
                              name="name"
                              type="text"
                              inputmode="text"
                              autocomplete="on"
                              aria-labelledby="name"
                              aria-autocomplete="list"
                              autocapitalize="on"
                              autocorrect="on"
                              clearInput={true}
                              onIonInput={onChange}
                              //onIonChange={onShippingChangeHandler}
                              value={value}
                            />
                          )}
                          control={control}
                          name="shipping_name"
                          autocomplete="on"
                          autocorrect="on"
                          rules={{
                            required: "Champs requis",
                            minLength: {
                              value: 2,
                              message: "Trop court",
                            },
                            maxLength: {
                              value: 50,
                              message: "Trop long",
                            },
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="shipping_name"
                          as={<div style={{ color: "red" }} />}
                        />
                      </IonItem>
                      <IonItem mode="md" key="shipping_company">
                        <IonLabel
                          color="medium shade"
                          className="ion-text-wrap"
                          position="floating"
                        >
                          Entreprise (optionnel)
                        </IonLabel>
                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <IonInput
                              type="text"
                              inputmode="text"
                              autocomplete="on"
                              autocorrect="on"
                              onIonInput={onChange}
                              //onIonChange={onShippingChangeHandler}
                              clearInput={true}
                              value={value}
                            />
                          )}
                          control={control}
                          name="shipping_company"
                          rules={{
                            required: false,
                            minLength: {
                              value: 2,
                              message: "Trop court",
                            },
                            maxLength: {
                              value: 50,
                              message: "Trop long",
                            },
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="shipping_company"
                          as={<div style={{ color: "red" }} />}
                        />
                      </IonItem>
                      <GoogleMapAuto
                        key="maps_suggestions"
                        onSelect={onSelectAddressHandler}
                      />

                      {/* shipping address 1 */}
                      <IonItem key="shipping_address_1" mode="md" hidden={true}>
                        <IonLabel
                          color="medium shade"
                          className="ion-text-wrap"
                          position="floating"
                        >
                          Adresse de livraison *
                        </IonLabel>
                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <IonInput
                              type="text"
                              inputmode="text"
                              autocomplete="on"
                              autocorrect="on"
                              onIonInput={onShippingChangeHandler}
                              onIonChange={onChange}
                              value={value}
                              clearInput={true}
                            />
                          )}
                          control={control}
                          name="shipping_address_1"
                          rules={{
                            required: "Champs requis",
                            //minLength: { value: 2, message: "nom trop court" },
                            maxLength: {
                              value: 50,
                              message: "Trop long",
                            },
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="shipping_address_1"
                          as={<div style={{ color: "red" }} />}
                        />
                      </IonItem>

                      {/*<GoogleMapAutoIntegrate key="maps_suggestions" onSelect={onSelectAddressHandler} searchValue={addressRef.current? addressRef.current.value!.toString() :''}/>*/}
                      {/* shipping address 2 */}
                      <IonItem key="shipping_address_2" mode="md">
                        <IonLabel
                          color="medium shade"
                          className="ion-text-wrap"
                          position="floating"
                        >
                          Complément d'adresse
                        </IonLabel>
                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <IonInput
                              type="text"
                              inputmode="text"
                              autocomplete="on"
                              autocorrect="on"
                              //onIonInput={onShippingChangeHandler}
                              onIonChange={onChange}
                              value={value}
                              clearInput={true}
                            />
                          )}
                          control={control}
                          name="shipping_address_2"
                          rules={{
                            required: false,
                            //minLength: { value: 2, message: "nom trop court" },
                            maxLength: {
                              value: 50,
                              message: "Trop long",
                            },
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="shipping_address_2"
                          as={<div style={{ color: "red" }} />}
                        />
                      </IonItem>
                      {/* shipping zip */}
                      <IonItem key="shipping_zip" mode="md">
                        <IonLabel
                          color="medium shade"
                          className="ion-text-wrap"
                          position="floating"
                        >
                          Code Postal *
                        </IonLabel>
                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <IonInput
                              type="text"
                              inputmode="text"
                              autocomplete="on"
                              autocorrect="on"
                              //onIonInput={onShippingChangeHandler}
                              onIonChange={onChange}
                              value={value}
                              clearInput={true}
                            />
                          )}
                          control={control}
                          name="shipping_zip"
                          rules={{
                            required: "Champs requis",
                            //minLength: { value: 2, message: "nom trop court" },
                            maxLength: { value: 50, message: "Trop long" },
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="shipping_zip"
                          as={<div style={{ color: "red" }} />}
                        />
                      </IonItem>
                      {/* shipping city */}
                      <IonItem key="shipping_city" mode="md">
                        <IonLabel
                          color="medium shade"
                          className="ion-text-wrap"
                          position="floating"
                        >
                          Ville *
                        </IonLabel>
                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <IonInput
                              type="text"
                              inputmode="text"
                              autocomplete="on"
                              autoCorrect="on"
                              //onIonInput={onShippingChangeHandler}
                              onIonChange={onChange}
                              value={value}
                              clearInput={true}
                            />
                          )}
                          control={control}
                          name="shipping_city"
                          rules={{
                            required: "Champs requis",
                            //minLength: { value: 2, message: "nom trop court" },
                            maxLength: {
                              value: 50,
                              message: "Trop long",
                            },
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="shipping_city"
                          as={<div style={{ color: "red" }} />}
                        />
                      </IonItem>
                      {/* shipping country */}
                      <IonItem key="shipping_country" mode="md">
                        <IonLabel
                          color="medium shade"
                          className="ion-text-wrap"
                          position="floating"
                        >
                          Pays *
                        </IonLabel>
                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <IonInput
                              type="text"
                              inputmode="text"
                              autocomplete="on"
                              autocorrect="on"
                              //onIonInput={onShippingChangeHandler}
                              onIonChange={onChange}
                              value={value}
                              clearInput={true}
                            />
                          )}
                          control={control}
                          name="shipping_country"
                          rules={{
                            required: "Champs requis",
                            //minLength: { value: 2, message: "nom trop court" },
                            maxLength: {
                              value: 50,
                              message: "Trop long",
                            },
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="shipping_country"
                          as={<div style={{ color: "red" }} />}
                        />
                      </IonItem>
                      {/* shipping phone */}
                      <IonItem
                        mode="md"
                        key="shipping_phone"
                        //className="ion-padding-bottom"
                      >
                        <IonLabel
                          color="medium shade"
                          className="ion-text-wrap"
                          position="floating"
                        >
                          Téléphone *
                        </IonLabel>
                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <IonInput
                              type="tel"
                              inputmode="numeric"
                              autocomplete="on"
                              autocorrect="on"
                              onIonInput={onChange}
                              //onIonChange={onShippingChangeHandler}
                              clearInput={true}
                              value={value}
                              aria-invalid={
                                errors.shipping_phone ? "true" : "false"
                              }
                            />
                          )}
                          control={control}
                          name="shipping_phone"
                          rules={{
                            required: "Champs requis",
                            minLength: {
                              value: 8,
                              message: "Trop court",
                            },
                            maxLength: {
                              value: 15,
                              message: "Trop long",
                            },
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="shipping_phone"
                          as={<div style={{ color: "red" }} />}
                        />
                      </IonItem>
                      <IonButton
                        //size="large"
                        disabled={isLoading ? true : false}
                        className="ion-no-margin ion-margin-top"
                        type="submit"
                        mode="ios"
                        expand="full"
                        color="dark"
                      >
                        <IonLabel style={{ fontWeight: 500 }}>
                          <p>Terminer</p>
                        </IonLabel>
                      </IonButton>
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default AddNewAddressModal;
