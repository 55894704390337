
import React from "react";

interface Context {
    isLoggedIn: boolean;
    userId: string|null;
    token: string|null;
    tokenExpirationDate: Date|null;
    cookieAgreement: boolean;
    login: (uid:string,token:string, expirationDate?:Date) => void;
    logout: () => void;
    acceptCookieAgreement: ()=>void;
}

const AuthContext = React.createContext<Context>({
    isLoggedIn: false,
    userId: null,
    token: null,
    tokenExpirationDate: null,
    cookieAgreement: false,
    login: () => {},
    logout: () => {},
    acceptCookieAgreement: ()=>{}
});

export default AuthContext;