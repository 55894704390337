import React, { useState, useEffect } from "react";

import FContext from "./f-context";

const FContextProvider: React.FC = (props) => {
  const [articleId, setArticleId] = useState<string>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).articleId !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).articleId
        : ""
      : ""
  );

  const [orderCode, setOrderCode] = useState<string>(
    localStorage.getItem("userFrame")
      ? JSON.parse(localStorage.getItem("userFrame")!).orderCode !== undefined
        ? JSON.parse(localStorage.getItem("userFrame")!).orderCode
        : ""
      : ""
  );
  const [imageUrl, setImageUrl] = useState<string>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).imageUrl !==
          undefined &&
        JSON.parse(localStorage.getItem("userFrame" + articleId)!).imageUrl !==
          ""
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).imageUrl
        : "https://images.unsplash.com/photo-1595878715977-2e8f8df18ea8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
      : "https://images.unsplash.com/photo-1595878715977-2e8f8df18ea8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
  );
  const [originalName, setOriginalName] = useState<string>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
          .originalName !== undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
            .originalName
        : ""
      : ""
  );
  const [minPriceList, setMinPriceList] = useState<Number[]>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
          .minPriceList !== undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
            .minPriceList
        : []
      : []
  );
  const [lamaList, setLamaList] = useState<string[]>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).lamaList !==
        undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).lamaList
        : []
      : []
  );
  const [imageWidth, setImageWidth] = useState<Number>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
          .imageWidth !== undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).imageWidth
        : 12
      : 12
  );
  const [imageHeight, setImageHeight] = useState<Number>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
          .imageHeight !== undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).imageHeight
        : 12
      : 12
  );
  const [wList, setWList] = useState<Number[]>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).wList !==
        undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).wList
        : []
      : []
  );
  const [hList, setHlist] = useState<Number[]>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).hList !==
        undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).hList
        : []
      : []
  );
  const [wSelected, setWSelected] = useState<Number>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).wSelected !==
        undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).wSelected
        : 12
      : 12
  );
  const [hSelected, setHSelected] = useState<Number>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).hSelected !==
        undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).hSelected
        : 12
      : 12
  );
  const [indexSelected, setIndexSelected] = useState<Number>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
          .indexSelected !== undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
            .indexSelected
        : 0
      : 0
  );

  const [customMessage, setCustomMessage] = useState<string>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
          .customMessage !== undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
            .customMessage
        : ""
      : ""
  );

  const [format, setFormat] = useState<string>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).format !==
        undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).format
        : ""
      : ""
  );

  const [matColor, setMatColor] = useState<string>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).matColor !==
        undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).matColor
        : "#FEFEFE"
      : "#FEFEFE"
  );
  const [matStyle, setMatStyle] = useState<string>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).matStyle !==
        undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).matStyle
        : "standard"
      : "standard"
  );
  const [matShape, setMatShape] = useState<string>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).matShape !==
        undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).matShape
        : "standard"
      : "standard"
  );
  const [matSizeTop, setMatSizeTop] = useState<number>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
          .matSizeTop !== undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).matSizeTop
        : 8
      : 8
  );

  const [matSizeBottom, setMatSizeBottom] = useState<number>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
          .matSizeBottom !== undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
            .matSizeBottom
        : 8
      : 8
  );

  const [matSizeRight, setMatSizeRight] = useState<number>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
          .matSizeRight !== undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
            .matSizeRight
        : 8
      : 8
  );

  const [matSizeLeft, setMatSizeLeft] = useState<number>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
          .matSizeleft !== undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).matSizeLeft
        : 8
      : 8
  );

  const [mount, setMount] = useState<string>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).mount !==
        undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).mount
        : "simple"
      : "simple"
  );
  const [artType, setArtType] = useState<string>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).artType !==
        undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).artType
        : ""
      : ""
  );
  const [mouldingName, setMouldingName] = useState<string>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
          .mouldingName !== undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
            .mouldingName
        : "Mambo (noir)"
      : "Mambo (noir)"
  );
  const [selectedStyle, setSelectedStyle] = useState<string>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
          .selectedStyle !== undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
            .selectedStyle
        : "standard"
      : "standard"
  );

  const [isCustomized, setIsCustomized] = useState<boolean>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
          .isCustomized !== undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
            .isCustomized
        : false
      : false
  );

  const [picsList, setPicsList] = useState<any>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).picsList !==
        undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!).picsList
        : false
      : false
  );

  const [counterpartName, setCounterpartName] = useState<any>(
    localStorage.getItem("userFrame" + articleId)
      ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
          .counterpartName !== undefined
        ? JSON.parse(localStorage.getItem("userFrame" + articleId)!)
            .counterpartName
        : ""
      : ""
  );

  const loadImageUrl = (url: string, width: Number, height: Number) => {
    setImageUrl(url); //callback guaranty last state used
    setImageWidth(width);
    setImageHeight(height);
  };

  const selectArticle = (article: string) => {
    const storedFrame = localStorage.getItem("userFrame" + article);
    const userFrame = localStorage.getItem("userFrame");
    localStorage.setItem(
      "userFrame",
      JSON.stringify({
        ...JSON.parse(userFrame ? userFrame : ""),
        orderCode: orderCode,
        articleId: article,
      })
    );
    if (storedFrame) {
      const parsedFrame = JSON.parse(storedFrame);
      setArticleId(parsedFrame.articleId);
      setOrderCode(parsedFrame.orderCode);
      setHlist(parsedFrame.hList);
      setWList(parsedFrame.wList);
      setOriginalName(parsedFrame.originalName);
      setCounterpartName(parsedFrame.counterpartName);
      setMinPriceList(parsedFrame.minPriceList);
      setLamaList(parsedFrame.lamaList);
      setImageHeight(parsedFrame.imageHeight);
      setImageWidth(parsedFrame.imageWidth);
      setImageUrl(parsedFrame.imageUrl);
      setIndexSelected(parsedFrame.indexSelected);
      setWSelected(parsedFrame.wSelected);
      setHSelected(parsedFrame.hSelected);
      setIndexSelected(parsedFrame.indexSelected);
      selectMatColor(parsedFrame.matColor);
      selectMatStyle(parsedFrame.matStyle);
      selectMatShape(parsedFrame.matShape);
      selectFormat(parsedFrame.format);
      selectMatSize(
        parsedFrame.matSizeTop,
        parsedFrame.matSizeBottom,
        parsedFrame.matSizeRight,
        parsedFrame.matSizeLeft
      );
      selectMount(parsedFrame.mount);
      setArtType(parsedFrame.artType);
      selectMouldingName(parsedFrame.mouldingName);
      setSelectedStyle(parsedFrame.selectedStyle);
      setIsCustomized(parsedFrame.isCustomized);
      setPicsList(parsedFrame.picsList);
      setCustomMessage(parsedFrame.customMessage);
    } else {
      setArticleId(article);
      setCustomMessage("");
      setPicsList(false);
      setFormat("");
      setMatColor("#FEFEFE");
      setMouldingName("Mambo (noir)");
      setImageUrl(
        "https://images.unsplash.com/photo-1595878715977-2e8f8df18ea8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
      );
    }
  };

  const selectArticleData = (
    article_id: string,
    custom_message: string,
    moulding_name: string,
    mat_color: string,
    pics_list: Array<string>,
    format: string
  ) => {
    setArticleId(article_id);
    setCustomMessage(custom_message);
    selectMouldingName(moulding_name);
    selectMatColor(mat_color);
    if (pics_list.length > 1) {
      setPicsList(
        pics_list.map((pic) => ({ src: pic, size: { width: 0, height: 0 } }))
      );
    } else {
      setImageUrl(pics_list[0]);
    }
    selectFormat(format);
  };

  const selectFormat = (format: string) => {
    setFormat(format);
  };

  const addImageUrl = (
    url: string,
    article_id: string,
    original_name: string,
    min_price_list: Number[],
    lama_list: string[],
    width: Number,
    height: Number,
    w_list: Number[],
    h_list: Number[]
  ) => {
    setImageUrl(url); //callback guaranty last state used
    setArticleId(article_id);
    setOriginalName(original_name);
    setMinPriceList(min_price_list);
    setLamaList(lama_list);
    setImageWidth(width);
    setImageHeight(height);
    setWList(w_list);
    setHlist(h_list);
    setIsCustomized(false);
    if (w_list.length > 2) {
      setIndexSelected(w_list.length - 3);
      setWSelected(w_list[w_list.length - 3]);
      setHSelected(h_list[h_list.length - 3]);
    } else if (w_list.length > 1) {
      setIndexSelected(w_list.length - 2);
      setWSelected(w_list[w_list.length - 2]);
      setHSelected(h_list[h_list.length - 2]);
    } else {
      setIndexSelected(w_list.length - 1);
      setWSelected(w_list[w_list.length - 1]);
      setHSelected(h_list[h_list.length - 1]);
    }
  };

  const selectSize = (
    selected_w: Number,
    selected_h: Number,
    index_selected: Number
  ) => {
    setWSelected(selected_w);
    setHSelected(selected_h);
    setIndexSelected(index_selected);
  };

  const selectImageSize = (
    new_width: number,
    new_height: number,
    new_path: string
  ) => {
    setImageWidth(new_width);
    setImageHeight(new_height);
    setImageUrl(new_path);
  };

  const selectMatColor = (color_selected: string) => {
    setMatColor(color_selected);
  };

  const selectMatStyle = (style_selected: string) => {
    setMatStyle(style_selected);
  };

  const selectMatShape = (shape_selected: string) => {
    setMatShape(shape_selected);
  };

  const selectMatSize = (
    top: number,
    bottom: number,
    right: number,
    left: number
  ) => {
    setMatSizeTop(top);
    setMatSizeBottom(bottom);
    setMatSizeRight(right);
    setMatSizeLeft(left);
  };

  const selectMount = (selected_mount: string) => {
    setMount(selected_mount);
  };

  const selectArtType = (selected_art_type: string) => {
    setArtType(selected_art_type);
  };

  const selectMouldingName = (selected_moulding: string) => {
    setMouldingName(selected_moulding);
  };

  const selectSelectedStyle = (selected_style: string) => {
    setSelectedStyle(selected_style);
  };

  const turnCustomized = () => {
    setIsCustomized(true);
  };

  const deleteImageUrl = () => {
    setImageUrl("");
  };

  const deleteFrame = () => {
    setArticleId("");
    setHlist([]);
    setWList([]);
    setOriginalName("");
    setMinPriceList([]);
    setLamaList([]);
    setImageHeight(0);
    setImageWidth(0);
    setImageUrl("");
    setIndexSelected(0);
    selectSize(0, 0, 0);
    selectMatColor("");
    selectMatStyle("");
    selectMatShape("");
    selectMatSize(0, 0, 0, 0);
    selectMount("");
    selectArtType("");
    selectMouldingName("");
    setArtType("");
    setSelectedStyle("");
    setIsCustomized(false);
    setPicsList([]);
    //localStorage.setItem('userFrame',JSON.stringify({}));
  };

  useEffect(() => {
    localStorage.setItem(
      "userFrame" + articleId,
      JSON.stringify({
        imageUrl,
        articleId,
        originalName,
        minPriceList,
        lamaList,
        imageWidth,
        imageHeight,
        wList,
        hList,
        counterpartName,
        wSelected,
        hSelected,
        indexSelected,
        matColor,
        matStyle,
        matShape,
        matSizeTop,
        matSizeBottom,
        matSizeRight,
        matSizeLeft,
        mount,
        artType,
        mouldingName,
        selectedStyle,
        isCustomized,
        picsList,
        customMessage,
        orderCode,
        format,
      })
    );
  }, [
    imageUrl,
    articleId,
    counterpartName,
    originalName,
    minPriceList,
    lamaList,
    imageWidth,
    imageHeight,
    wList,
    hList,
    wSelected,
    hSelected,
    indexSelected,
    format,
    matColor,
    matStyle,
    matShape,
    matSizeTop,
    matSizeBottom,
    matSizeRight,
    matSizeLeft,
    mount,
    artType,
    mouldingName,
    selectedStyle,
    isCustomized,
    picsList,
    setPicsList,
    orderCode,
    customMessage,
  ]);

  return (
    <FContext.Provider
      value={{
        imageUrl,
        articleId,
        counterpartName,
        originalName,
        minPriceList,
        lamaList,
        imageWidth,
        imageHeight,
        wList,
        hList,
        wSelected,
        hSelected,
        indexSelected,
        matColor,
        matStyle,
        matShape,
        matSizeTop,
        matSizeBottom,
        matSizeRight,
        matSizeLeft,
        mount,
        artType,
        orderCode,
        customMessage,
        format,
        loadImageUrl,
        addImageUrl,
        mouldingName,
        selectedStyle,
        isCustomized,
        picsList,
        selectSize,
        selectMatColor,
        selectMatStyle,
        selectMatShape,
        selectMatSize,
        selectMount,
        selectArtType,
        selectMouldingName,
        deleteFrame,
        selectSelectedStyle,
        selectArticle,
        selectImageSize,
        turnCustomized,
        setPicsList,
        deleteImageUrl,
        setCounterpartName,
        setOrderCode,
        setCustomMessage,
        selectArticleData,
        selectFormat,
      }}
    >
      {props.children}
    </FContext.Provider>
  );
};

export default FContextProvider;
