import React, { useState, useEffect, useContext, useRef } from "react";
import {
  IonToolbar,
  IonPage,
  IonHeader,
  IonButtons,
  IonButton,
  IonIcon,
  IonProgressBar,
  isPlatform,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonCard,
  IonCardContent,
  IonList,
  IonListHeader,
  IonCheckbox,
  IonRadio,
  IonRadioGroup,
  IonBackButton,
  IonToast,
  IonTitle,
  IonSpinner,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHttpClient } from "../hooks/http-hook";
import { useHistory, useParams } from "react-router";
import AddNewAddressModal from "../components/order/AddNewAddressModal";
import FooterRows from "../components/shared/nav/FooterRows";
import GoogleMapAutoIntegrate from "../components/order/GoogleMapsAutoIntegrate";
import fContext from "../context/f-context";

// set the default values for the controls
let initialValues = {
  shipping_name: "",
  shipping_surname: "",
  shipping_company: "",
  shipping_phone: "",
  shipping_address_1: "",
  shipping_address_2: "",
  shipping_city: "",
  shipping_zip: "",
  shipping_country: "",
  billing_name: "",
  billing_surname: "",
  billing_company: "",
  billing_address_1: "",
  billing_address_2: "",
  billing_city: "",
  billing_zip: "",
  billing_country: "",
  same_billing_address: true,
};

const ShippingAddress: React.FC = () => {
  const fCtx = useContext(fContext);
  const { control, handleSubmit, errors, getValues, setValue, watch } = useForm(
    {
      defaultValues: { ...initialValues },
      mode: "onChange",
    }
  );
  const { isLoading, error, sendRequest } = useHttpClient();
  const history = useHistory();
  let selectedOrder = useParams<{ orderId: string }>().orderId;
  const [sameInvoiceAddress, setSameInvoiceAddress] = useState<boolean>(true);
  const [sameShippingAddress, setSameShippingAddress] = useState<boolean>(true);
  const addressRef = useRef<HTMLIonInputElement | null>(null);
  const [showShippingAdress, setShowShippingAddress] = useState<boolean>(false);

  const [savedAddresses, setSavedAddresses] = useState<Array<any>>([]);
  const [shippingAddress, setShippingAddress] = useState<string>("");
  const [billingAddress, setBillingAddress] = useState<string>("");
  const [showNewAddressModal, setShowNewAddressModal] =
    useState<boolean>(false);

  const [showToast, setShowToast] = useState<boolean>(false);
  const watchShippingAddress1 = watch("shipping_address_1", "");

  const addressSubmitHandler = async (data: any) => {
    //Check IF INVOICE CHECKED AND SET VALUES
    if (shippingAddress === "") {
      alert("Veuillez sélectionner une adresse de livraison");
    } else if (!sameInvoiceAddress && billingAddress === "") {
      alert("Veuillez sélectionner une adresse de facturation");
    } else if (!sameShippingAddress && shippingAddress === "") {
      alert("Veuillez sélectionner une adresse de livraison de votre commande");
    } else {
      try {
        data.shipping_address_id = shippingAddress;
        if (sameInvoiceAddress) {
          data.billing_address_id = shippingAddress;
        } else {
          data.billing_address_id = billingAddress;
        }
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}api/ulule/order/${selectedOrder}`,
          "POST",
          JSON.stringify(data),
          {
            "Content-Type": "application/json",
          }
        );
        history.push(`/${selectedOrder}`);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onSelectShippingAddressHandler = (
    address: String,
    city: String,
    zip: String,
    country: String
  ) => {
    setShowShippingAddress(true);
    setValue("shipping_address_1", address.toString());
    setValue("shipping_zip", zip.toString());
    setValue("shipping_city", city.toString());
    setValue("shipping_country", country.toString());
  };

  const invoiceChangeHandler = () => {
    if (sameInvoiceAddress === false) {
      setSameInvoiceAddress(true);
    } else {
      setSameInvoiceAddress(false);
      setValue("billing_address_1", "");
      setValue("billing_address_2", "");
      setValue("billing_city", "");
      setValue("billing_country", "");
      setValue("billing_zip", "");
    }
  };

  const showAddNewAddressHandler = () => {
    setShowNewAddressModal(!showNewAddressModal);
  };

  const onSavedNewAddressHandler = () => {
    //const tempSelectedOrder = selectedOrder;
    //selectedOrder = '';
    //selectedOrder = tempSelectedOrder;
    fetchOrderData();
    setShowNewAddressModal(false);
  };

  const fetchOrderData = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}api/ulule/getOrder/${selectedOrder}`
      );

      if (responseData.shippingAddress) {
        setValue("shipping_name", responseData.shippingAddress.name);
        setValue("shipping_surname", responseData.shippingAddress.surname);
        setValue("shipping_phone", responseData.shippingAddress.phone);
        setSavedAddresses(responseData.savedAddresses);
        setShippingAddress(responseData.shippingAddress._id);
        setBillingAddress(responseData.billingAddress._id);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchOrderData();
  }, [sendRequest, selectedOrder]);

  return (
    <IonPage>
      <IonToast
        isOpen={showToast}
        message={error !== null ? error! : ""}
        onDidDismiss={(e) => setShowToast(false)}
        keyboardClose={true}
        buttons={[
          {
            icon: "close",
            role: "cancel",
            side: "start",
            handler: () => {
              setShowToast(false);
            },
          },
        ]}
      />
      <AddNewAddressModal
        show={showNewAddressModal}
        onCancel={showAddNewAddressHandler}
        selectedOrder={selectedOrder}
        onSaved={onSavedNewAddressHandler}
      />
      <IonHeader
        style={{ backgroundColor: "white" }}
        no-border
        className="ion-no-border"
      >
        <IonToolbar
          className="ion-text-center"
          style={{ backgroundColor: "white" }}
        >
          {isPlatform("mobile") && (
            <IonButtons slot="start">
              <IonBackButton text="">
                <IonIcon size="large" icon={arrowBackOutline}></IonIcon>
              </IonBackButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSubmit((data) => addressSubmitHandler(data))}>
          <IonGrid className="ion-no-padding">
            <IonRow
              style={{
                minHeight: "calc(100vh - 150px)",
                paddingBottom: "80px",
              }}
            >
              <IonCol
                size="12"
                size-lg="6"
                offset-lg="3"
                className="ion-padding"
              >
                {savedAddresses.length < 1 && (
                  <React.Fragment>
                    <IonItem lines="none">
                      <IonText>
                        <h2 style={{ whiteSpace: "pre-line" }}>
                          Votre adresse ?
                        </h2>
                        <br />
                      </IonText>
                    </IonItem>
                    <IonCard mode="md">
                      <IonCardContent>
                        <IonList>
                          <IonListHeader
                            key="first_title"
                            className="ion-padding-top"
                            mode="ios"
                          >
                            Livraison de votre commande
                          </IonListHeader>
                          {/* shipping surname */}
                          <React.Fragment>
                            <IonItem mode="md" key="shipping_surname">
                              <IonLabel
                                color="medium shade"
                                className="ion-text-wrap"
                                position="floating"
                              >
                                Prénom *
                              </IonLabel>
                              <Controller
                                render={({ onChange, onBlur, value }) => (
                                  <IonInput
                                    type="text"
                                    inputmode="text"
                                    autocomplete="on"
                                    name="name"
                                    autocorrect="on"
                                    aria-autocomplete="list"
                                    autofocus={true}
                                    onIonInput={onChange}
                                    //onIonChange={onShippingChangeHandler}
                                    clearInput={true}
                                    value={value}
                                    enterkeyhint="next"
                                    aria-invalid={
                                      errors.shipping_surname ? "true" : "false"
                                    }
                                  />
                                )}
                                control={control}
                                name="shipping_surname"
                                rules={{
                                  required: "Champs requis",
                                  minLength: {
                                    value: 2,
                                    message: "Trop court",
                                  },
                                  maxLength: {
                                    value: 50,
                                    message: "Trop long",
                                  },
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="shipping_surname"
                                as={<div style={{ color: "red" }} />}
                              />
                            </IonItem>
                            {/* shipping name */}
                            <IonItem mode="md" key="shipping_name">
                              <IonLabel
                                color="medium shade"
                                className="ion-text-wrap"
                                position="floating"
                              >
                                Nom *
                              </IonLabel>
                              <Controller
                                render={({ onChange, onBlur, value }) => (
                                  <IonInput
                                    id="name"
                                    name="name"
                                    type="text"
                                    inputmode="text"
                                    autocomplete="on"
                                    aria-labelledby="name"
                                    aria-autocomplete="list"
                                    autocapitalize="on"
                                    autocorrect="on"
                                    clearInput={true}
                                    onIonInput={onChange}
                                    //onIonChange={onShippingChangeHandler}
                                    value={value}
                                  />
                                )}
                                control={control}
                                name="shipping_name"
                                autocomplete="on"
                                autocorrect="on"
                                rules={{
                                  required: "Champs requis",
                                  minLength: {
                                    value: 2,
                                    message: "Trop court",
                                  },
                                  maxLength: {
                                    value: 50,
                                    message: "Trop long",
                                  },
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="shipping_name"
                                as={<div style={{ color: "red" }} />}
                              />
                            </IonItem>
                            <IonItem mode="md" key="shipping_company">
                              <IonLabel
                                color="medium shade"
                                className="ion-text-wrap"
                                position="floating"
                              >
                                Entreprise (optionnel)
                              </IonLabel>
                              <Controller
                                render={({ onChange, onBlur, value }) => (
                                  <IonInput
                                    type="text"
                                    inputmode="text"
                                    autocomplete="on"
                                    autocorrect="on"
                                    onIonInput={onChange}
                                    //onIonChange={onShippingChangeHandler}
                                    clearInput={true}
                                    value={value}
                                  />
                                )}
                                control={control}
                                name="shipping_company"
                                rules={{
                                  required: false,
                                  minLength: {
                                    value: 2,
                                    message: "Trop court",
                                  },
                                  maxLength: {
                                    value: 50,
                                    message: "Trop long",
                                  },
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="shipping_company"
                                as={<div style={{ color: "red" }} />}
                              />
                            </IonItem>
                            {/* <GoogleMapAuto
                                key="maps_suggestions"
                                onSelect={onSelectShippingAddressHandler}
                             />*/}
                            {/* shipping address 1 */}
                            <IonItem
                              key="shipping_address_1"
                              mode="md"
                              //hidden={!showShippingAdress}
                            >
                              <IonLabel
                                color="medium shade"
                                className="ion-text-wrap"
                                position="floating"
                              >
                                {"Adresse de livraison *"}
                              </IonLabel>
                              <Controller
                                render={({ onChange, onBlur, value }) => (
                                  <IonInput
                                    type="text"
                                    inputmode="text"
                                    autocomplete="off"
                                    autocorrect="off"
                                    //onIonInput={onShippingChangeHandler}
                                    onIonChange={onChange}
                                    value={value}
                                    clearInput={true}
                                    ref={addressRef}
                                  />
                                )}
                                control={control}
                                name="shipping_address_1"
                                rules={{
                                  required: "Champs requis",
                                  //minLength: { value: 2, message: "nom trop court" },
                                  maxLength: {
                                    value: 50,
                                    message: "Trop long",
                                  },
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="shipping_address_1"
                                as={<div style={{ color: "red" }} />}
                              />
                            </IonItem>
                            {
                              <GoogleMapAutoIntegrate
                                key="maps_suggestions"
                                onSelect={onSelectShippingAddressHandler}
                                searchValue={watchShippingAddress1}
                              />
                            }
                            {/* shipping address 2 */}
                            <IonItem
                              key="shipping_address_2"
                              mode="md"
                              //hidden={!showShippingAdress}
                            >
                              <IonLabel
                                color="medium shade"
                                className="ion-text-wrap"
                                position="floating"
                              >
                                Complément d'adresse
                              </IonLabel>
                              <Controller
                                render={({ onChange, onBlur, value }) => (
                                  <IonInput
                                    type="text"
                                    inputmode="text"
                                    autocomplete="on"
                                    autocorrect="on"
                                    //onIonInput={onShippingChangeHandler}
                                    onIonChange={onChange}
                                    value={value}
                                    clearInput={true}
                                  />
                                )}
                                control={control}
                                name="shipping_address_2"
                                rules={{
                                  required: false,
                                  //minLength: { value: 2, message: "nom trop court" },
                                  maxLength: {
                                    value: 50,
                                    message: "Trop long",
                                  },
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="shipping_address_2"
                                as={<div style={{ color: "red" }} />}
                              />
                            </IonItem>
                            {/* shipping zip */}
                            <IonItem
                              key="shipping_zip"
                              mode="md"
                              //hidden={!showShippingAdress}
                            >
                              <IonLabel
                                color="medium shade"
                                className="ion-text-wrap"
                                position="floating"
                              >
                                Code Postal *
                              </IonLabel>
                              <Controller
                                render={({ onChange, onBlur, value }) => (
                                  <IonInput
                                    type="text"
                                    inputmode="text"
                                    autocomplete="on"
                                    autocorrect="on"
                                    //onIonInput={onShippingChangeHandler}
                                    onIonChange={onChange}
                                    value={value}
                                    clearInput={true}
                                  />
                                )}
                                control={control}
                                name="shipping_zip"
                                rules={{
                                  required: "Champs requis",
                                  //minLength: { value: 2, message: "nom trop court" },
                                  maxLength: {
                                    value: 50,
                                    message: "Trop long",
                                  },
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="shipping_zip"
                                as={<div style={{ color: "red" }} />}
                              />
                            </IonItem>
                            {/* shipping city */}
                            <IonItem
                              key="shipping_city"
                              mode="md"
                              //hidden={!showShippingAdress}
                            >
                              <IonLabel
                                color="medium shade"
                                className="ion-text-wrap"
                                position="floating"
                              >
                                Ville *
                              </IonLabel>
                              <Controller
                                render={({ onChange, onBlur, value }) => (
                                  <IonInput
                                    type="text"
                                    inputmode="text"
                                    autocomplete="on"
                                    autoCorrect="on"
                                    //onIonInput={onShippingChangeHandler}
                                    onIonChange={onChange}
                                    value={value}
                                    clearInput={true}
                                  />
                                )}
                                control={control}
                                name="shipping_city"
                                rules={{
                                  required: "Champs requis",
                                  //minLength: { value: 2, message: "nom trop court" },
                                  maxLength: {
                                    value: 50,
                                    message: "Trop long",
                                  },
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="shipping_city"
                                as={<div style={{ color: "red" }} />}
                              />
                            </IonItem>
                            {/* shipping country */}
                            <IonItem
                              key="shipping_country"
                              mode="md"
                              //hidden={!showShippingAdress}
                            >
                              <IonLabel
                                color="medium shade"
                                className="ion-text-wrap"
                                position="floating"
                              >
                                Pays *
                              </IonLabel>
                              <Controller
                                render={({ onChange, onBlur, value }) => (
                                  <IonInput
                                    type="text"
                                    inputmode="text"
                                    autocomplete="on"
                                    autocorrect="on"
                                    //onIonInput={onShippingChangeHandler}
                                    onIonChange={onChange}
                                    value={value}
                                    clearInput={true}
                                  />
                                )}
                                control={control}
                                name="shipping_country"
                                rules={{
                                  required: "Champs requis",
                                  //minLength: { value: 2, message: "nom trop court" },
                                  maxLength: {
                                    value: 50,
                                    message: "Trop long",
                                  },
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="shipping_country"
                                as={<div style={{ color: "red" }} />}
                              />
                            </IonItem>
                            {/* shipping phone */}
                            <IonItem
                              mode="md"
                              key="shipping_phone"
                              //className="ion-padding-bottom"
                              //hidden={!showShippingAdress}
                            >
                              <IonLabel
                                color="medium shade"
                                className="ion-text-wrap"
                                position="floating"
                              >
                                Téléphone *
                              </IonLabel>
                              <Controller
                                render={({ onChange, onBlur, value }) => (
                                  <IonInput
                                    type="tel"
                                    inputmode="numeric"
                                    autocomplete="on"
                                    autocorrect="on"
                                    onIonInput={onChange}
                                    //onIonChange={onShippingChangeHandler}
                                    clearInput={true}
                                    value={value}
                                    aria-invalid={
                                      errors.shipping_phone ? "true" : "false"
                                    }
                                  />
                                )}
                                control={control}
                                name="shipping_phone"
                                rules={{
                                  required: "Champs requis",
                                  minLength: {
                                    value: 8,
                                    message: "Trop court",
                                  },
                                  maxLength: {
                                    value: 15,
                                    message: "Trop long",
                                  },
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="shipping_phone"
                                as={<div style={{ color: "red" }} />}
                              />
                            </IonItem>
                          </React.Fragment>
                          <IonListHeader
                            key="billing_title"
                            className="ion-padding-top"
                            mode="ios"
                          >
                            Facturation
                          </IonListHeader>
                          <IonItem key="same_invoice_address" lines="none">
                            <IonLabel
                              style={{ marginTop: "0px" }}
                              className="ion-text-wrap"
                            >
                              <p style={{ marginTop: "0px" }}>
                                Identique à la livraison
                              </p>
                            </IonLabel>
                            <IonCheckbox
                              mode="ios"
                              color="secondary"
                              checked={sameInvoiceAddress}
                              onIonChange={invoiceChangeHandler}
                              style={{ marginTop: "0px" }}
                            />
                          </IonItem>
                          {/* FACTURATION FIELDS-------------------------------------- */}
                          {/* billing company */}
                          <IonItem
                            key="billing_company"
                            mode="md"
                            hidden={sameInvoiceAddress}
                          >
                            <IonLabel
                              color="medium shade"
                              className="ion-text-wrap"
                              position="floating"
                            >
                              Entreprise (optionnel)
                            </IonLabel>
                            <Controller
                              render={({ onChange, onBlur, value }) => (
                                <IonInput
                                  type="text"
                                  inputmode="text"
                                  autocomplete="on"
                                  autocorrect="on"
                                  onIonChange={onChange}
                                  clearInput={true}
                                  value={
                                    sameInvoiceAddress
                                      ? getValues("shipping_company")
                                      : value
                                  }
                                />
                              )}
                              control={control}
                              name="billing_company"
                              rules={{
                                required: false,
                                minLength: {
                                  value: 2,
                                  message: "Trop court",
                                },
                                maxLength: {
                                  value: 50,
                                  message: "Trop long",
                                },
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="billing_company"
                              as={<div style={{ color: "red" }} />}
                            />
                          </IonItem>
                          {/* billing surname */}
                          <IonItem
                            key="billing_surname"
                            mode="md"
                            hidden={sameInvoiceAddress}
                          >
                            <IonLabel
                              color="medium shade"
                              className="ion-text-wrap"
                              position="floating"
                            >
                              Prénom *
                            </IonLabel>
                            <Controller
                              render={({ onChange, onBlur, value }) => (
                                <IonInput
                                  type="text"
                                  inputmode="text"
                                  autocomplete="on"
                                  autocorrect="on"
                                  onIonChange={onChange}
                                  clearInput={true}
                                  value={
                                    sameInvoiceAddress
                                      ? getValues("shipping_surname")
                                      : value
                                  }
                                />
                              )}
                              control={control}
                              name="billing_surname"
                              rules={{
                                required: false,
                                minLength: {
                                  value: 2,
                                  message: "Trop court",
                                },
                                maxLength: {
                                  value: 50,
                                  message: "Trop long",
                                },
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="billing_surname"
                              as={<div style={{ color: "red" }} />}
                            />
                          </IonItem>
                          {/* billing name */}
                          <IonItem
                            key="billing_name"
                            mode="md"
                            hidden={sameInvoiceAddress}
                          >
                            <IonLabel
                              color="medium shade"
                              className="ion-text-wrap"
                              position="floating"
                            >
                              Nom *
                            </IonLabel>
                            <Controller
                              render={({ onChange, onBlur, value }) => (
                                <IonInput
                                  type="text"
                                  inputmode="text"
                                  autocomplete="on"
                                  autocorrect="on"
                                  onIonChange={onChange}
                                  clearInput={true}
                                  value={
                                    sameInvoiceAddress
                                      ? getValues("shipping_name")
                                      : value
                                  }
                                />
                              )}
                              control={control}
                              name="billing_name"
                              rules={{
                                required: false,
                                minLength: {
                                  value: 2,
                                  message: "Trop court",
                                },
                                maxLength: {
                                  value: 50,
                                  message: "Trop long",
                                },
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="billing_name"
                              as={<div style={{ color: "red" }} />}
                            />
                          </IonItem>
                          {/* billing address 1 */}
                          <IonItem
                            key="billing_address_1"
                            mode="md"
                            hidden={sameInvoiceAddress}
                          >
                            <IonLabel
                              color="medium shade"
                              className="ion-text-wrap"
                              position="floating"
                            >
                              Adresse de livraison *
                            </IonLabel>
                            <Controller
                              render={({ onChange, onBlur, value }) => (
                                <IonInput
                                  type="text"
                                  inputmode="text"
                                  autocomplete="on"
                                  autocorrect="on"
                                  onIonChange={onChange}
                                  clearInput={true}
                                  value={
                                    sameInvoiceAddress
                                      ? getValues("shipping_address_1")
                                      : value
                                  }
                                />
                              )}
                              control={control}
                              name="billing_address_1"
                              rules={{
                                required: false,
                                //minLength: { value: 2, message: "nom trop court" },
                                maxLength: {
                                  value: 50,
                                  message: "Trop long",
                                },
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="billing_address_1"
                              as={<div style={{ color: "red" }} />}
                            />
                          </IonItem>
                          {/* billing address 2 */}
                          <IonItem
                            key="billing_address_2"
                            mode="md"
                            hidden={sameInvoiceAddress}
                          >
                            <IonLabel
                              color="medium shade"
                              className="ion-text-wrap"
                              position="floating"
                            >
                              Complément d'adresse
                            </IonLabel>
                            <Controller
                              render={({ onChange, onBlur, value }) => (
                                <IonInput
                                  type="text"
                                  inputmode="text"
                                  autocomplete="on"
                                  autocorrect="on"
                                  onIonChange={onChange}
                                  clearInput={true}
                                  value={
                                    sameInvoiceAddress
                                      ? getValues("shipping_address_2")
                                      : value
                                  }
                                />
                              )}
                              control={control}
                              name="billing_address_2"
                              rules={{
                                required: false,
                                //minLength: { value: 2, message: "nom trop court" },
                                maxLength: {
                                  value: 50,
                                  message: "Trop long",
                                },
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="billing_address_2"
                              as={<div style={{ color: "red" }} />}
                            />
                          </IonItem>
                          {/* billing zip*/}
                          <IonItem
                            key="billing_zip"
                            mode="md"
                            hidden={sameInvoiceAddress}
                          >
                            <IonLabel
                              color="medium shade"
                              className="ion-text-wrap"
                              position="floating"
                            >
                              Code Postal *
                            </IonLabel>
                            <Controller
                              render={({ onChange, onBlur, value }) => (
                                <IonInput
                                  type="text"
                                  inputmode="text"
                                  autocomplete="on"
                                  autocorrect="on"
                                  onIonChange={onChange}
                                  clearInput={true}
                                  value={
                                    sameInvoiceAddress
                                      ? getValues("shipping_zip")
                                      : value
                                  }
                                />
                              )}
                              control={control}
                              name="billing_zip"
                              rules={{
                                required: false,
                                //minLength: { value: 2, message: "nom trop court" },
                                maxLength: {
                                  value: 50,
                                  message: "Trop long",
                                },
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="billing_zip"
                              as={<div style={{ color: "red" }} />}
                            />
                          </IonItem>
                          {/* billing city*/}
                          <IonItem
                            key="billing_city"
                            mode="md"
                            hidden={sameInvoiceAddress}
                          >
                            <IonLabel
                              color="medium shade"
                              className="ion-text-wrap"
                              position="floating"
                            >
                              Ville *
                            </IonLabel>
                            <Controller
                              render={({ onChange, onBlur, value }) => (
                                <IonInput
                                  type="text"
                                  inputmode="text"
                                  autocomplete="on"
                                  autocorrect="on"
                                  onIonChange={onChange}
                                  clearInput={true}
                                  value={
                                    sameInvoiceAddress
                                      ? getValues("shipping_city")
                                      : value
                                  }
                                />
                              )}
                              control={control}
                              name="billing_city"
                              rules={{
                                required: false,
                                //minLength: { value: 2, message: "nom trop court" },
                                maxLength: {
                                  value: 50,
                                  message: "Trop long",
                                },
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="billing_city"
                              as={<div style={{ color: "red" }} />}
                            />
                          </IonItem>
                          {/* billing country */}
                          <IonItem
                            key="billing_country"
                            mode="md"
                            hidden={sameInvoiceAddress}
                          >
                            <IonLabel
                              color="medium shade"
                              className="ion-text-wrap"
                              position="floating"
                            >
                              Pays *
                            </IonLabel>
                            <Controller
                              render={({ onChange, onBlur, value }) => (
                                <IonInput
                                  type="text"
                                  inputmode="text"
                                  autocomplete="on"
                                  autocorrect="on"
                                  onIonChange={onChange}
                                  clearInput={true}
                                  value={
                                    sameInvoiceAddress
                                      ? getValues("shipping_country")
                                      : value
                                  }
                                />
                              )}
                              control={control}
                              name="billing_country"
                              rules={{
                                required: false,
                                //minLength: { value: 2, message: "nom trop court" },
                                maxLength: {
                                  value: 50,
                                  message: "Trop long",
                                },
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="billing_country"
                              as={<div style={{ color: "red" }} />}
                            />
                          </IonItem>
                          <IonButton
                            //size="large"
                            className="ion-no-margin ion-margin-top"
                            type="submit"
                            mode="ios"
                            expand="full"
                            color="dark"
                          >
                            <IonText>
                              <p className="text-button">Enregistrer</p>
                            </IonText>
                          </IonButton>
                        </IonList>
                      </IonCardContent>
                    </IonCard>
                  </React.Fragment>
                )}
                {savedAddresses.length > 0 && (
                  <React.Fragment>
                    <IonItem lines="none">
                      <IonText>
                        <h2>
                          Votre adresse ?
                          <br />
                        </h2>
                      </IonText>
                    </IonItem>
                    <IonListHeader
                      key="billing_title"
                      className="ion-padding-top"
                      mode="ios"
                    >
                      Livraison de votre commande
                    </IonListHeader>
                    <React.Fragment>
                      <IonRadioGroup
                        value={shippingAddress}
                        onIonChange={(e) => setShippingAddress(e.detail.value)}
                        key="shippingAddress"
                      >
                        {savedAddresses.map((address) => (
                          <IonCard>
                            <IonItem lines="none">
                              <IonText color="medium" className="text-disable">
                                <p
                                  style={{
                                    marginBottom: "4px",
                                    marginTop: "24px",
                                  }}
                                >
                                  {address.company !== "" && address.company}
                                </p>

                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "4px",
                                  }}
                                >
                                  {address.surname} {address.name}
                                </p>

                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "4px",
                                  }}
                                >
                                  {address.address_1}
                                </p>

                                {address.address_2 !== "" && (
                                  <p
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "4px",
                                    }}
                                  >
                                    {address.address_2}
                                  </p>
                                )}

                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "4px",
                                  }}
                                >
                                  {address.zip}, {address.city}
                                </p>

                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "4px",
                                  }}
                                >
                                  {address.country}
                                </p>

                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "24px",
                                  }}
                                >
                                  {address.phone}
                                </p>
                              </IonText>

                              <IonRadio
                                slot="end"
                                mode="md"
                                value={address._id}
                              />
                            </IonItem>
                          </IonCard>
                        ))}
                      </IonRadioGroup>
                      <IonButton fill="clear" color="undefined">
                        <IonText
                          color="dark"
                          className="ion-text-center"
                          onClick={showAddNewAddressHandler}
                        >
                          <p
                            style={{
                              textDecoration: "underline",
                              fontWeight: 300,
                            }}
                          >
                            + Ajouter une nouvelle addresse
                          </p>
                        </IonText>
                      </IonButton>
                    </React.Fragment>
                    <IonListHeader
                      key="billing_title"
                      className="ion-padding-top"
                      mode="ios"
                    >
                      Facturation
                    </IonListHeader>
                    <IonItem key="same_invoice_address" lines="none">
                      <IonLabel
                        style={{ marginTop: "0px" }}
                        className="ion-text-wrap"
                      >
                        <p style={{ marginTop: "0px" }}>
                          Identique à la livraison
                        </p>
                      </IonLabel>
                      <IonCheckbox
                        mode="ios"
                        color="secondary"
                        checked={sameInvoiceAddress}
                        onIonChange={invoiceChangeHandler}
                        style={{ marginTop: "0px" }}
                      />
                    </IonItem>
                    {!sameInvoiceAddress && (
                      <IonRadioGroup
                        value={billingAddress}
                        onIonChange={(e) => setBillingAddress(e.detail.value)}
                        key="billingAddress"
                      >
                        {savedAddresses.map((address) => (
                          <IonCard>
                            <IonItem lines="none">
                              <IonText color="medium" className="text-disable">
                                <p
                                  style={{
                                    marginBottom: "4px",
                                    marginTop: "24px",
                                  }}
                                >
                                  {address.company !== "" && address.company}
                                </p>

                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "4px",
                                  }}
                                >
                                  {address.surname} {address.name}
                                </p>

                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "4px",
                                  }}
                                >
                                  {address.address_1}
                                </p>

                                {address.address_2 !== "" && (
                                  <p
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "4px",
                                    }}
                                  >
                                    {address.address_2}
                                  </p>
                                )}

                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "4px",
                                  }}
                                >
                                  {address.zip}, {address.city}
                                </p>

                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "4px",
                                  }}
                                >
                                  {address.country}
                                </p>

                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "24px",
                                  }}
                                >
                                  {address.phone}
                                </p>
                              </IonText>

                              <IonRadio
                                slot="end"
                                mode="md"
                                value={address._id}
                              />
                            </IonItem>
                          </IonCard>
                        ))}
                        <IonButton fill="clear" color="undefined">
                          <IonText
                            color="dark"
                            className="ion-text-center"
                            onClick={showAddNewAddressHandler}
                          >
                            <p
                              style={{
                                textDecoration: "underline",
                                fontWeight: 300,
                              }}
                            >
                              + Ajouter une nouvelle addresse
                            </p>
                          </IonText>
                        </IonButton>
                      </IonRadioGroup>
                    )}
                  </React.Fragment>
                )}
              </IonCol>
              {savedAddresses.length > 0 && (
                <IonCol
                  size="12"
                  size-lg="6"
                  offset-lg="3"
                  className="ion-no-padding"
                >
                  <IonButton
                    //size="large"
                    className="ion-no-margin ion-margin-top"
                    type="submit"
                    mode="ios"
                    expand="full"
                    color="dark"
                    disabled={isLoading}
                  >
                    <IonLabel>
                      <p className="text-button">Terminer</p>
                    </IonLabel>
                    {isLoading && (
                      <IonSpinner
                        className="ion-margin-left"
                        color="tertiary"
                        name="crescent"
                      />
                    )}
                  </IonButton>
                </IonCol>
              )}
            </IonRow>
            <FooterRows />
          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default ShippingAddress;
