import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, isPlatform, IonLoading } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ScreenOrientation } from "@ionic-native/screen-orientation";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/home.css";
//import "./theme/build-size.css";
import "./theme/variables.css";
import "./theme/theme.css";
import CookieModal from "./components/shared/nav/CookieModal";
import FContextProvider from "./context/fContextProvider";
import OrderContextProvider from "./context/orderContextProvider";
import { IntercomProvider } from "react-use-intercom";
import ShippingAddress from "./pages/shipping-address";
const Landing = React.lazy(() => import("./pages/landing"));
const Product = React.lazy(() => import("./pages/product"));
const ProductDetails = React.lazy(() => import("./pages/product-details"));
const GalleryWall = React.lazy(() => import("./pages/GalleryWall"));

const INTERCOM_APP_ID = "ne7h3qqb";
/*Context & Hooks loading */

const App: React.FC = () => {
  useEffect(() => {
    if (isPlatform("mobile")) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }
  }, []);

  useEffect(() => {
    const ele = document.getElementById("ipl-progress-indicator");
    if (ele) {
      // fade out
      ele.classList.add("available");
      // remove from DOM
      ele.outerHTML = "";
    }
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IntercomProvider autoBoot={true} appId={INTERCOM_APP_ID}>
          <OrderContextProvider>
            <FContextProvider>
              <React.Suspense
                fallback={
                  <IonLoading
                    //cssClass='my-custom-class'
                    isOpen={true}
                    message={"Chargement..."}
                  />
                }
              >
                <CookieModal />
                <main>
                  <IonRouterOutlet id="main" animated={false}>
                    <Route path={"/:code"} exact>
                      <Landing />
                    </Route>
                    <Route path={"/product/:productName"} exact>
                      <Product />
                    </Route>
                    <Route
                      path={"/productDetails/:productName/:mouldingName"}
                      exact
                    >
                      <ProductDetails />
                    </Route>
                    <Route path={"/shipping-address/:orderId"}>
                      <ShippingAddress />
                    </Route>
                    <Route path={"/productDetails/:productName"} exact>
                      <ProductDetails />
                    </Route>
                    <Route
                      path={
                        "/gallery-wall-configurator/:productName/:mouldingName"
                      }
                      exact
                    >
                      <GalleryWall />
                    </Route>
                    <Route path="/">
                      <Landing />
                    </Route>
                  </IonRouterOutlet>
                </main>
              </React.Suspense>
            </FContextProvider>
          </OrderContextProvider>
        </IntercomProvider>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
