import React, { useState, useCallback, useEffect } from "react";
import { Article, Order } from "../types/main";

import OrderContext from "./order-context";

const OrderContextProvider: React.FC = (props) => {
  const [email, setEmail] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [articles, setArticles] = useState<Array<Article>>([]);
  const [orderFirstName, setOrderFirstName] = useState<string | null>(null);
  const [orderLastName, setOrderLastName] = useState<string | null>(null);
  const [cookieAgreement, setCookieAgreement] = useState<boolean>(
    localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")!).cookieAgreement !==
        undefined
        ? JSON.parse(localStorage.getItem("userData")!).cookieAgreement
        : false
      : false
  );

  const setOrder = (order: Order) => {
    setEmail(order.email);
    setCode(order.code);
    setArticles(order.articles);
    setOrderFirstName(order.orderFirstName);
    setOrderLastName(order.orderLastName);
  };

  const acceptCookieAgreement = () => {
    setCookieAgreement(true);
  };

  useEffect(() => {
    localStorage.setItem(
      "userData",
      JSON.stringify({
        email,
        code,
        articles,
        orderFirstName,
        orderLastName,
        cookieAgreement,
      })
    );
  }, [email, code, articles, orderFirstName, orderLastName, cookieAgreement]);

  return (
    <OrderContext.Provider
      value={{
        email,
        code,
        orderFirstName,
        orderLastName,
        articles,
        cookieAgreement,
        setOrder,
        acceptCookieAgreement,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};

export default OrderContextProvider;
