
import React from "react";
import { Article, Order } from "../types/main";

interface Context {
    code: string|null;
    email: string|null;
    orderFirstName: string | null;
    orderLastName: string | null;
    cookieAgreement: boolean;
    articles: Array<Article>;
    setOrder: (order: Order)=>void;
    acceptCookieAgreement: ()=>void;
}

const OrderContext = React.createContext<Context>({
    code: null,
    email: null,
    orderFirstName: null,
    orderLastName: null,
    articles: [],
    cookieAgreement: false,
    setOrder: ()=>{},
    acceptCookieAgreement: ()=>{},
});

export default OrderContext;