import React, { useContext } from "react";
import { IonToast, isPlatform } from "@ionic/react";
import OrderContext from "../../../context/order-context";

const CookieModal: React.FC<{}> = () => {
  const orderCtx = useContext(OrderContext);

  return (
    <IonToast
      isOpen={!orderCtx.cookieAgreement}
      message={
        "Nous utilisons des cookies pour nous permettre de mieux comprendre comment le site est utilisé. En continuant à utiliser ce site, vous acceptez cette politique."
      }
      onDidDismiss={(e) => orderCtx.acceptCookieAgreement()}
      keyboardClose={true}
      mode="ios"
      //duration={10000}
      position={isPlatform("mobile") ? "top" : "bottom"}
      buttons={[
        {
          text: "ok",
          role: "cancel",
          side: "end",
          handler: () => {
            orderCtx.acceptCookieAgreement();
          },
        },
      ]}
    />
  );
};

export default CookieModal;
