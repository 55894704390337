import React from "react";
import {
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonText,
  IonGrid,
  isPlatform,
} from "@ionic/react";
import { logoFacebook, logoInstagram, logoPinterest } from "ionicons/icons";

const FooterRows: React.FC = () => {
  const lamaFactoryURL = "https://www.lamafactory.com";
  return (
    <React.Fragment>
      <IonRow
        style={{
          background: "var(--ion-color-dark)",
          borderBottom: "solid 1px white",
        }}
      >
        <IonCol
          size="12"
          className="ion-text-center ion-padding-top ion-padding-bottom"
          style={{ borderBottom: "solid 1px white" }}
        >
          <IonButton
            href="https://www.facebook.com/lamafactory"
            target="Facebook"
            fill="clear"
            color="light"
          >
            <IonIcon slot="icon-only" icon={logoFacebook} />
          </IonButton>
          <IonButton
            href="https://www.instagram.com/lamafactory_com/"
            target="Instagram"
            fill="clear"
            color="light"
          >
            <IonIcon slot="icon-only" icon={logoInstagram} />
          </IonButton>
          <IonButton
            href="https://www.pinterest.fr/lama_factory_design/"
            target="Pinterest"
            fill="clear"
            color="light"
          >
            <IonIcon slot="icon-only" icon={logoPinterest} />
          </IonButton>
        </IonCol>
        <IonCol
          size="12"
          size-md="10"
          offset-md="1"
          size-lg="8"
          offset-lg="2"
          size-xl="7"
          offset-xl="2.5"
        >
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol size="4" className="ion-padding">
                <IonText color="light">
                  <a
                    href={lamaFactoryURL}
                    target="Lama Factory"
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                  >
                    {" "}
                    <p className="text-footer-title">LAMA FACTORY</p>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/gallery"}
                  >
                    {" "}
                    <p className="text-footer-link">Tous nos cadres</p>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/know-how"}
                  >
                    {" "}
                    <p className="text-footer-link">Matériaux et fabrication</p>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/how-it-works"}
                  >
                    {" "}
                    <p className="text-footer-link">Le concept</p>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/pricing"}
                  >
                    {" "}
                    <p className="text-footer-link">Prix</p>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/guide"}
                  >
                    {" "}
                    <p className="text-footer-link">Conseils design cadres</p>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/our-mission"}
                  >
                    {" "}
                    <p className="text-footer-link">Notre mission</p>
                  </a>
                  <p className="ion-margin-top text-footer-title">
                    ENCADREMENTS
                  </p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/cadre-photo-sur-mesure"}
                  >
                    {" "}
                    <p className="text-footer-link">Cadre photo sur mesure</p>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/cadre-photo-pele-mele"}
                  >
                    {" "}
                    <p className="text-footer-link">Cadre photo pêle-mêle</p>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/cadre-sur-mesure"}
                  >
                    {" "}
                    <p className="text-footer-link">Cadre sur mesure</p>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/caisse-americaine"}
                  >
                    {" "}
                    <p className="text-footer-link">Caisse américaine</p>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/cadre-bois"}
                  >
                    {" "}
                    <p className="text-footer-link">Cadre bois</p>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/cadre-dore"}
                  >
                    {" "}
                    <p className="text-footer-link">Cadre doré</p>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/cadre-tableau"}
                  >
                    {" "}
                    <p className="text-footer-link">Cadre tableau</p>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/cadre-maillot"}
                  >
                    {" "}
                    <p className="text-footer-link">Cadre maillot</p>
                  </a>
                </IonText>
              </IonCol>
              <IonCol size="4" className="ion-padding">
                <IonText color="light">
                  <p className="text-footer-title">SERVICES CLIENTS</p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/services"}
                  >
                    {" "}
                    <p className="text-footer-link">Nos services</p>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/faq"}
                  >
                    {" "}
                    <p className="text-footer-link">FaQ</p>
                  </a>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href={lamaFactoryURL + "/account/orders"}
                  >
                    {" "}
                    <p className="text-footer-link">Mon compte</p>
                  </a>
                  <a
                    href="mailto: hello@lamafactory.com"
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                  >
                    {" "}
                    <p className="text-footer-link">Nous contacter</p>
                  </a>
                </IonText>
              </IonCol>
              <IonCol
                size="4"
                className={
                  isPlatform("mobile") ? "ion-padding" : "ion-padding y-align"
                }
                style={{ borderLeft: "solid 1px white" }}
              >
                <IonText className="ion-text-wrap" color="light">
                  <p style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    Une question ? Une idée à partager ?
                  </p>
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--ion-color-light)",
                    }}
                    href="mailto: hello@lamafactory.com"
                  >
                    {" "}
                    <p
                      style={{ paddingLeft: "8px", paddingRight: "8px" }}
                      className="text-footer-link"
                    >
                      👉 hello@lamafactory.com
                    </p>
                  </a>
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCol>
      </IonRow>
      <IonRow style={{ background: "var(--ion-color-dark)" }}>
        <IonCol
          size="12"
          size-md="6"
          className="ion-padding-start ion-text-left"
        >
          <IonText color="light">
            <p>© Lama Factory 2021, fait à la 🖐 entre Lyon et Paris</p>
          </IonText>
        </IonCol>
        <IonCol
          size="12"
          size-md="6"
          className="ion-padding-end ion-text-right"
        >
          <IonText color="light">
            <a
              href={lamaFactoryURL + "/legal"}
              style={{
                display: "inline-block",
                paddingRight: "16px",
                textDecoration: "none",
                color: "var(--ion-color-light)",
              }}
            >
              {" "}
              <p className="text-footer-link">Mentions légales</p>
            </a>
            <a
              href={lamaFactoryURL + "/trade"}
              style={{
                display: "inline-block",
                textDecoration: "none",
                color: "var(--ion-color-light)",
              }}
            >
              {" "}
              <p className="text-footer-link">CGV</p>
            </a>
          </IonText>
        </IonCol>
      </IonRow>
    </React.Fragment>
  );
};

export default FooterRows;
