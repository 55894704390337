import React from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
  LatLon,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import {
  IonInput,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonSpinner,
  IonText,
} from "@ionic/react";

const scope = new google.maps.LatLng({ lat: 48.865576, lng: 2.378785 });

const GoogleMapAuto: React.FC<{
  onSelect: (
    address: String,
    city: String,
    zip: String,
    country: String
  ) => void;
}> = (props) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here  cite di figuier*/
      location: scope,
      //radius in meters
      radius: 1000 * 1000,
    },
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  /*const handleInput = (e:CustomEvent<InputEvent>) => {
      console.log(e);
    // Update the keyword of the input element
    if(e.target !=null && e.target != null){
    setValue(e.details.value.toString());
    }
  };*/

  const handleSelect = (suggestion: any) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(suggestion.description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: suggestion.description })
      .then(
        (results) => {
          console.log(results);

          let route = results[0].address_components.filter((item) =>
            item.types.includes("route")
          )[0]?.long_name;
          let street_number = results[0].address_components.filter((item) =>
            item.types.includes("street_number")
          )[0]?.long_name;
          let locality = results[0].address_components.filter((item) =>
            item.types.includes("locality")
          )[0]?.long_name;
          let postal_code = results[0].address_components.filter((item) =>
            item.types.includes("postal_code")
          )[0]?.long_name;
          let country = results[0].address_components.filter((item) =>
            item.types.includes("country")
          )[0]?.long_name;

          props.onSelect(
            (street_number ? street_number : "") + " " + (route ? route : ""),
            locality ? locality : "",
            postal_code ? postal_code : "",
            country ? country : ""
          );
          clearSuggestions();
        }
        /*getZipCode(results[0], false)*/
      )
      /*.then((zipCode) => {
          
        //console.log("📍 Coordinates: ", { lat, lng });
        props.onSelect(suggestion.terms[0].value+" "+suggestion.terms[1].value,suggestion.terms[2].value, zipCode? zipCode:"", suggestion.terms[3].value);
        console.log(zipCode);
      })*/
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const renderSuggestions = () => {
    return (
      <React.Fragment>
        <IonItem color="light">
          <IonText>
            <i>Suggestions</i>
          </IonText>
        </IonItem>
        {data.map((suggestion) => (
          <IonItem
            color="dark tint"
            detail={false}
            button
            key={suggestion.id}
            onClick={handleSelect(suggestion)}
          >
            <strong>{suggestion.structured_formatting.main_text}</strong>{" "}
            <small>
              {" "}
              &nbsp; {suggestion.structured_formatting.secondary_text}
            </small>
          </IonItem>
        ))}
      </React.Fragment>
    );
  };

  return (
    <div ref={ref}>
      <React.Fragment>
        <IonItem mode="md" detail={false} button key="google_search">
          <IonLabel
            color="medium shade"
            className="ion-text-wrap"
            position="floating"
          >
            Adresse de livraison *
          </IonLabel>
          <IonInput
            value={value}
            onIonChange={(e) => setValue(e.detail.value!)}
            disabled={!ready}
            clearInput={true}
            autocomplete="off"
            name="aLamaDrss"
          />
          {!ready && <IonSpinner />}
        </IonItem>
        {/*<IonSearchbar
        className='ion-no-padding ion-padding-top'
          mode="ios"
          //value={value}
          onIonChange={(e) => setValue(e.detail.value!)}
          //showCancelButton="focus"
          placeholder="Rechercher votre adresse "
          animated
          disabled={!ready}
        >{!ready &&<IonSpinner/>}</IonSearchbar>*/}
        {/* We can use the "status" to decide whether we should display the dropdown or not */}
        {status === "OK" && renderSuggestions()}
      </React.Fragment>
    </div>
  );
};

export default GoogleMapAuto;
